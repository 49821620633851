<script setup lang="ts">
import { computed, type HtmlHTMLAttributes } from 'vue';
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue';
import { CheckboxRoot, useForwardPropsEmits } from 'radix-vue';
import { cn } from '@laam/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const toggleButtonVariants = cva(
	/*tw*/ 'peer rounded-medium inline-flex items-center gap-xs justify-center whitespace-nowrap text-sm ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-0 focus-visible:ring-opacity-15 disabled:pointer-events-none font-medium bg-white border-gray-300 border text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400 data-[state=checked]:text-gray-800 data-[state=checked]:border-gray-800',
	{
		variants: {
			variant: {
				outline: '',
				default:
					/*tw*/ 'data-[state=checked]:bg-gray-700 data-[state=checked]:text-white',
			},
			size: {
				xs: /*tw*/ 'py-sm px-lg text-sm [&_svg]:h-3xl [&_svg]:w-3xl',
				sm: /*tw*/ ' py-md px-xl text-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				md: /*tw*/ ' py-lg px-2xl text-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				lg: /*tw*/ ' py-lg px-3xl text-md gap-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				xl: /*tw*/ ' py-4xl px-4xl text-md gap-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				'2xl':
					/*tw*/ ' py-3xl px-6xl text-lg gap-lg [&_svg]:h-7xl [&_svg]:w-7xl',
			},
		},
		defaultVariants: {
			size: 'xs',
			variant: 'default',
		},
	},
);

export type ToggleButtonVariants = VariantProps<typeof toggleButtonVariants>;

interface Props extends CheckboxRootProps {
	class?: HtmlHTMLAttributes['class'];
	size?: ToggleButtonVariants['size'];
	variant?: ToggleButtonVariants['variant'];
}

const props = withDefaults(defineProps<Props>(), {
	class: '',
	size: 'xs',
	variant: 'default',
});
const emits = defineEmits<CheckboxRootEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
	const { class: _, size, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<CheckboxRoot
		v-bind="forwarded"
		:class="cn(toggleButtonVariants({ size, variant }), props.class)"
	>
		<slot />
	</CheckboxRoot>
</template>
